import React from 'react';
import { Button, Hidden } from '@material-ui/core';
import './SwitchLanguage.css';
import { useTranslation } from 'react-i18next';
import { supportedLangs } from '../../i18n/common';
import usaFlag from '../../../assets/images/usa.png';
import indianFlag from '../../../assets/images/ind.png';
import { useHistory } from "react-router-dom";


const imagesLanguageMapping = {
    en_IN: usaFlag,
    hi_IN: indianFlag
}

function SwitchMyLanguage() {
    const { t, i18n } = useTranslation('app');
    const history = useHistory();
    return (
        <div style={{ display: 'flex' }}>
            <Hidden smDown>
                <div style={{ flexGrow: 1 }}></div>
            </Hidden>
            <div className="switch">
                {
                    Object.keys(supportedLangs).map(langCode => (
                        <div key={langCode}>
                            <Button
                                onClick={() => i18n.changeLanguage(langCode, () => {
                                    history.push(supportedLangs[langCode].urlSlug);
                                })}
                                variant="contained"
                                className={i18n.language === langCode ? 'switch__button active' : 'switch__button'}
                            >
                                <img src={imagesLanguageMapping[langCode]} alt={supportedLangs[langCode].text} /> {t(supportedLangs[langCode].text, supportedLangs[langCode].fallbackTrans)}
                            </Button>
                        </div>
                    ))
                }
            </div>
        </div >
    );
}
export default SwitchMyLanguage;
