import types from './types';

const initialState = {
    isLoggedIn: null,
    userData: {}
};

const reducers = (state = initialState, actions) => {
    switch (actions.type) {
        case types.USER_LOGGED_IN:
            return {
                isLoggedIn: true,
                userData: {
                    ...state.userData,
                    firstName: actions.payload.firstName,
                    isLender: actions.payload.isLender
                }
            };
        case types.USER_LOGGED_OUT:
            return {
                isLoggedIn: false,
                userData: {
                    ...state.userData,
                    firstName: null,
                    isLender: null
                }
            };
        default:
            return state;
    }
}

export default reducers;