import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000'
        },
        secondary: {
            main: '#fff'
        }
    },

    typography: {
        fontFamily: "'Open Sans', sans-serif"
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 1024,
            lg: 1280,
            xl: 1920
        }
    }
});

export default theme;