import { hot } from 'react-hot-loader/root';
import { Layout } from '../layout';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import { supportedLangs, fallbackLocale } from '../i18n/common';

const localesRegex = Object.keys(supportedLangs).reduce((regexStr, locale) => {
    regexStr += regexStr ? '|' : '';
    return regexStr += (locale === fallbackLocale) ? '' : locale
}, '');

const localeSlugString = `/:locale(${localesRegex})?`;

export const routes = [
    {
        path: localeSlugString + "/",
        exact: true,
        menu: 'Home',
        component: Home
    },
    {
        path: localeSlugString + '/*',
        component: NotFound
    }
];

export default [
    {
        component: hot(Layout),
        routes
    }
];
